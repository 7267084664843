import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Loader from 'react-js-loader';

export default function ProvateRoute({component, ...props}) {
  const {user, isLoading} = useContext(AuthContext);

  if(isLoading) return <div className="container--center-content">
    <Loader type="spinner-circle" bgColor="#323484" size={150} color="#323484"/>
  </div>;

  if(user) {
    return (
      <Route {...props} component={component}/>
    )
  } else {
    return <Redirect to='/'/>
  }
}
