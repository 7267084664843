import React from 'react'
import { BrowserRouter, Switch, Route, useRouteMatch } from 'react-router-dom'
import Dashboard from '../views/Dashboard/Dashboard'
import Login from '../views/Login/Login'
import Logs from '../views/Logs/Logs'
import OpenGate from '../views/OpenGate/OpenGate'
import Users from '../views/Users/Users'
import { Sidebar } from '../components/Sidebar/Sidebar'
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'
import AdminRoute from '../components/AdminRoute/AdminRoute'
import AccessCodes from '../views/AccessCodes/AccessCodes'

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/code/:codeParam" component={Login}/>
        <PrivateRoute path="/app" component={AppRouter}/>
      </Switch>
    </BrowserRouter>
  )
}

const AppRouter = () => {
  const {path} = useRouteMatch();

  return (
    <div className="container">
      <Sidebar/>
      <Switch>
        <AdminRoute exact path={`${path}`} component={Dashboard}/>
        <AdminRoute exact path={`${path}/users`} component={Users}/>
        <AdminRoute exact path={`${path}/logs`} component={Logs}/>
        <AdminRoute exact path={`${path}/codes`} component={AccessCodes}/>
        <Route exact path={`${path}/open-gate`} component={OpenGate}/>
      </Switch>
    </div>
  )
}
