import React from "react";
import "./OpenGate.scss";
import { Widget } from "../../components/Widget/Widget";
import OpenGateBtn from "../../components/OpenGateBtn/OpenGateBtn";

export default function OpenGate() {
  return (
    <Widget className="open-gate-widget button-container" color="#fa8164" center>
      <OpenGateBtn/>
    </Widget>
  );
}
