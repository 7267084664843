import React, { useEffect, useState } from "react";
import "./CodeList.scss";
import axios from "axios";
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Button } from "../Button/Button";
import { HiOutlineBan } from "react-icons/hi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaCalendarTimes } from 'react-icons/fa';

export const CodeList = ({ itemLimit, className, refreshTrigger, ...props }) => {
  const [codes, setCodes] = useState([]);

  const fetchCodes = () => {
    axios.get(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/codes`).then(res => {
      setCodes(res.data.codes);
    });
  }

  useEffect(() => {
    fetchCodes();
  }, [refreshTrigger]);

  const deactivateCode = (codeId, state) => {
    axios.patch(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/codes/${codeId}`, {isActive: state}).then(() => {
      fetchCodes();
    }).catch(err => {
      console.log(err);
    });
  }

  const removeCode = codeId => {
    axios.delete(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/codes/${codeId}`).then(() => {
      fetchCodes();
    });
  }

  const isValidCode = code => {
    return (!code?.useLimit || code?.useLimit > 0) &&
      (!code?.validFrom || new Date(code.validFrom) < new Date()) &&
      (!code?.validTo || new Date(code.validTo) > new Date());
  }

  return (
    <>
    <table className={`code-list${className ? ' '+className : ''}`} {...props}>
      <thead className="code-list__item--heading">
        <tr>
          <td>Kod</td>
          <td>Nazwa</td>
          <td>Aktywny</td>
          <td>Pozostało użyć</td>
          <td>Ważny od</td>
          <td>Ważny do</td>
          <td>Akcje</td>
        </tr>
      </thead>
      <tbody>
        {codes.map(code =>
          <tr className="code-list__item" key={code.code}>
            <td>{code?.code}</td>
            <td>{code?.name}</td>
            <td
              title={!isValidCode(code) ? 'Kod stracił ważność lub osiągnął limit użyć' : !code?.isActive ? 'Kod deaktywowany ręcznie' : ''} 
            >
              {(!isValidCode(code)) ? <FaCalendarTimes className="icon--red"/> : code?.isActive ? <AiOutlineCheckCircle className="icon--green"/> : <AiOutlineCloseCircle className="icon--red" />}
            </td>
            <td>{code?.useLimit ?? '-'}</td>
            <td>{code?.validFrom ? (new Date(code?.validFrom)).toLocaleString() : '-'}</td>
            <td>{code?.validTo ? (new Date(code?.validTo)).toLocaleString() : '-'}</td>
            <td>
            {!!isValidCode(code) && <Button className={`button--icon${code?.isActive ? ' button--green' : ''} code-list__item-action`} title={code?.isActive ? 'Aktywuj kod' : 'Dezaktywuj kod'} onClick={() => {deactivateCode(code?.code, !code?.isActive)}}>
                <HiOutlineBan />
              </Button>}
              <Button className="button--icon button--red code-list__item-action" title="Usuń kod dostępu" onClick={() => {removeCode(code?.code)}}>
                <MdOutlineDeleteOutline />
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
    </>
  );
};
