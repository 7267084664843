import axios from "axios";
import React, { useState } from "react";
import { Button } from "../Button/Button";
import { FormGroup, TextField } from "../Form/Form";
import "./AddUser.scss";

export default function AddUser() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const createUser = () => {
    axios.post(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/users`, {
      name: username,
      login: login,
      password: password,
      role: isAdmin ? 'admin' : 'user'
    }).then(() => {
      setMessage('Uzytkownik został dodany!');
      setUsername('');
      setLogin('');
      setPassword('');
      setIsAdmin(false);
    }).catch(err => {
      setError(err.response.data.message || err.message);
    });
  };

  return (
    <form className="add-user__form" onSubmit={createUser}>
      <FormGroup>
        <TextField
          className="add-user__text-field"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        >
          Nazwa użytkownika
        </TextField>
      </FormGroup>

      <FormGroup>
        <TextField
          className="add-user__text-field"
          value={login}
          onChange={(e) => {
            setLogin(e.target.value);
          }}
        >
          Login
        </TextField>
      </FormGroup>

      <FormGroup>
        <TextField
          className="add-user__text-field"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        >
          Hasło
        </TextField>
      </FormGroup>

      <FormGroup>
        <label>
          <input type="checkbox" checked={isAdmin} onChange={() => {setIsAdmin(!isAdmin)}} /> Uprawnienia administratora
        </label>
      </FormGroup>

      {!!(error || message) && <FormGroup>
          <span className={error ? 'error' : 'message'}>{error || message}</span>
        </FormGroup>
      }

      <FormGroup className="add-user__button-container">
        <Button
          type="submit"
          className="add-user__button"
          onClick={createUser}
          preventDefault
        >
          Dodaj użytkownika
        </Button>
      </FormGroup>
    </form>
  );
}
