import React from "react";
import "./Logs.scss";
import { Widget } from "../../components/Widget/Widget";
import { LogList } from "../../components/LogList/LogList";

export const Logs = () => {
  return (
    <Widget className="list-container" id="log-list">
      <LogList/>
    </Widget>
  );
};

export default Logs;
