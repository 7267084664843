import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import useObjectState from '../../hooks/useObjectState';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './LogList.scss';

export const LogList = ({itemLimit, refreshTrigger}) => {
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useObjectState({
    logs: [],
    currentPage: 1,
    lastPage: 1,
  });
  const itemsInContainer = useMemo(() => 
    width > 1200 ? Math.floor((window.innerHeight - 115) / 35) : 20,
  [width]);

  useEffect(() => {
    axios.get(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/logs?size=${itemsInContainer}`).then(res => {
      setState({
        logs: res.data.data,
        currentPage: parseInt(res.data.currentPage),
        lastPage: parseInt(res.data.lastPage),
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }, [refreshTrigger]);
  
  const fetchMoreData = () => {
    if(state.currentPage >= state.lastPage) return;

    axios.get(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/logs?page=${state.currentPage+1}&size=${itemsInContainer}`).then(res => {
      setState({
        logs: [...state.logs, ...res.data.data],
        currentPage: parseInt(res.data.currentPage),
        lastPage: parseInt(res.data.lastPage),
      });
    });
  }

  return (
    <ul className="log-list">
      <li className="log-list__item--heading">
        <span>Data</span>
        <span>Akcja</span>
      </li>
      {!isLoading && (
        <InfiniteScroll
          dataLength={state.logs.length}
          next={fetchMoreData}
          hasMore={state.currentPage < state.lastPage}
          endMessage={
            <p className="end-message">To już wszystkie logi</p>
          }
          scrollableTarget={width > 1200 ? "log-list" : ""}
        >
          {(itemLimit ? state.logs.slice(0, itemLimit) : state.logs).map((log, index) =>
            <li className="log-list__item" key={index}>
              <span>{(new Date(log.date)).toLocaleString()}</span>
              <span>{log.user?.id ? 'Użytkownik' : ''} {log.user?.name || log.user?.login || log.code?.name} {log.action === 'open' ? 'otworzył bramę' : `wykonał akcję (${log.action})`} {log.code?.code ? `kodem dla gości: ${log.code?.code}` : ''}</span>
            </li>
          )}
        </InfiniteScroll>
      )}
    </ul>
  )
}
