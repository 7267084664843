import React, { useContext, useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { UserBadge } from "../UserBadge/UserBadge";
import { HiOutlineBan } from "react-icons/hi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import "./UserList.scss";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";

export const UserList = ({ itemLimit, className, ...props }) => {
  const [users, setUsers] = useState([]);
  const {user: loggedUser} = useContext(AuthContext);

  const fetchUsers = () => {
    axios.get(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/users`).then(res => {
      setUsers(res.data.users);
    });
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const banUser = (userId, ban) => {
    axios.patch(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/users/${userId}`, {isBanned: ban}).then(() => {
      fetchUsers();
    }).catch(err => {
      console.log(err);
    });
  }

  const removeUser = userId => {
    axios.delete(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/users/${userId}`).then(() => {
      fetchUsers();
    });
  }

  return (
    <ul className={`user-list${className ? ' '+className : ''}`} {...props}>
      {(itemLimit ? users.slice(0, itemLimit) : users).map((user) => (
        <UserBadge
          user={user}
          key={user.id}
          actions={user.id === loggedUser.id ? null :
            <>
              <Button className={`button--icon${user.isBanned ? ' button--green' : ''}`} title={user.isBanned ? 'Odbanuj użytkownika' : 'Banuj użytkownika'} onClick={() => {banUser(user.id, !user.isBanned)}}>
                <HiOutlineBan />
              </Button>
              <Button className="button--icon button--red" title="Usuń użytkownika" onClick={() => {removeUser(user.id)}}>
                <MdOutlineDeleteOutline />
              </Button>
            </>
          }
        />
      ))}
    </ul>
  );
};
