import React, { useState } from "react";
import "./AccessCodes.scss";
import { Widget } from "../../components/Widget/Widget";
import { CodeList } from "../../components/CodeList/CodeList";
import { DateField, Form, FormGroup, FormSeparator, FormTitle, NumberField, TextField, TextToCopy } from "../../components/Form/Form";
import { Button } from "../../components/Button/Button";
import axios from "axios";
import { format } from "date-fns";

export default function AccessCodes() {
  const [name, setName] = useState('');
  const [useLimit, setUseLimit] = useState('');
  const [validFrom, setValidFrom] = useState('');
  const [validTo, setValidTo] = useState('');
  const [text, setText] = useState('');

  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const [trigger, setTrigger] = useState(false);

  const generateAccessCode = () => {
    axios.post(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/codes`, {
      name,
      useLimit: (!!useLimit && parseInt(useLimit) > 0) ? useLimit : null,
      validFrom: validFrom || null,
      validTo: validTo || null
    }).then(res => {
      const code = res.data.code;
      const url = `${process.env.REACT_APP_SITE_URL}code/${code}`;
      setMessage('Kod został wygenerowany!');
      setText(`Otrzymałeś dostęp do bramy wjazdowej.
      Aby otworzyć bramę, użyj Twojego unikalnego kodu dostępu <b>${code}</b> lub linku bezpośredniego <a href='${url}'>${url}</a>.
      ${useLimit ? 'Kod może zostać użyty <b>'+useLimit+'</b> '+(useLimit > 1 ? 'razy' : 'raz')+'.' : ''}
      ${(validTo || validFrom) ? 'Kod jest ważny ' : ''}
      ${validFrom ? 'od <b>'+(new Date(validFrom).toLocaleString())+'</b>' : ''}
      ${validTo ? 'do <b>'+(new Date(validTo).toLocaleString())+'</b>' : ''}
      ${(validTo || validFrom) ? '.' : ''}`)
      setName('');
      setUseLimit('');
      setValidFrom('');
      setValidTo('');
      setTrigger(!trigger);
    }).catch(err => {
      setError(err.response.data.message || err.message);
    });
  }

  return (
    <div className="codes-container">
      <Widget width="30%" color="#b3aada" className="scrollbar--orange">
        <Form className="add-code-form">
          <FormTitle small>Dodaj kod dostępu</FormTitle>
          <FormGroup>
            <TextField
              id="name"
              placeholder="Osoba dla której generujesz kod"
              value={name}
              onChange={e => setName(e.target.value)}
            >Nazwa</TextField>
          </FormGroup>
          <FormSeparator>Ograniczenia</FormSeparator>
          <FormGroup>
            <NumberField
              id="uses"
              min="1"
              value={useLimit}
              onChange={e => setUseLimit(e.target.value)}
            >Ilość użyć</NumberField>
          </FormGroup>
          <FormGroup>
            <DateField
              id="validFrom"
              value={validFrom}
              onChange={e => setValidFrom(e.target.value)}
            >Ważny od</DateField>
          </FormGroup>
          <FormGroup>
            <DateField
              id="validTo"
              value={validTo}
              onChange={e => setValidTo(e.target.value)}
              min={format(validFrom ? new Date(validFrom) : new Date(), "yyyy-MM-dd'T'HH:mm")}
            >Ważny do</DateField>
          </FormGroup>
          {!!(error || message) && <FormGroup>
            <span className={error ? 'error' : ''}>{error || message}</span>
            </FormGroup>
          }
          <FormGroup>
            <Button
              preventDefault
              onClick={generateAccessCode}
            >Generuj kod</Button>
          </FormGroup>
          <FormSeparator>Tekst zaproszeniowy</FormSeparator>
          <FormGroup>
            <TextToCopy
              placeholder="Po dodaniu kodu skopiuj tekst i prześlij osobie, której chcesz udzielić dostępu"
            >{text}</TextToCopy>
          </FormGroup>
        </Form>
      </Widget>
      <Widget width="70%">
        <CodeList refreshTrigger={trigger}/>
      </Widget>
    </div>
  );
}
