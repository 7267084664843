import React from "react";
import "./Widget.scss";

export const Widget = ({
  children,
  className,
  color,
  width,
  center,
  ...props
}) => {
  return (
    <div
      className={`widget${className ? " " + className : ""}${
        center ? " widget--center" : ""
      }`}
      style={{ backgroundColor: color, width: width }}
      {...props}
    >
      {children}
    </div>
  );
};
