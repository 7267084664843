import React from 'react';
import './App.scss';
import { AuthContext } from './contexts/AuthContext';
import useGetUser from './hooks/useGetUser';
import Router from './router/Router';

function App() {
  const {user, setUser, isLoading} = useGetUser();

  return (
    <AuthContext.Provider value={{user, setUser, isLoading}}>
      <Router/>
    </AuthContext.Provider>
  );
}

export default App;
