import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LogList } from "../../components/LogList/LogList";
import { UserList } from "../../components/UserList/UserList";
import { Widget } from "../../components/Widget/Widget";
import "./Dashboard.scss";
import AddUser from "../../components/AddUser/AddUser";
import OpenGateBtn from "../../components/OpenGateBtn/OpenGateBtn";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function Dashboard() {
  const { height } = useWindowDimensions();
  const [trigger, setTrigger] = useState(false);

  const refreshLogs = () => {
    setTrigger(!trigger);
  }

  return (
    <div className="dashboard">
      <section className="dashboard__section">
        <h2 className="dashboard__section-title">Dashboard</h2>
        <div className="dashboard__widgets">
          <Widget width="33%" color="#FA8164" center className="open-gate-widget">
            <OpenGateBtn refreshLogs={refreshLogs}/>
          </Widget>
          <Widget width="33%">
            <UserList itemLimit={Math.floor((height - 300) / 100)} />
            <Link to="/app/users" className="button--text center">
              Wszyscy użytkownicy
            </Link>
          </Widget>
          <Widget width="33%" color="#323484" className="scrollbar--orange">
            <AddUser />
          </Widget>
        </div>
      </section>
      <section className="dashboard__section">
        <h2 className="dashboard__section-title">Logi</h2>
        <div className="dashboard__widgets">
          <Widget width="100%">
            <LogList itemLimit={Math.floor((height - 300) / 80)} refreshTrigger={trigger}/>
            <Link to="/app/logs" className="button--text center">
              Wszystkie logi
            </Link>
          </Widget>
        </div>
      </section>
    </div>
  );
}
