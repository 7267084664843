import { format } from 'date-fns';
import React from 'react'
import { selectText } from '../../helpers/SelectText';
import './Form.scss';

export const Form = ({children, className, ...props}) => {
  return (
    <form className={`form ${className ? ' '+className : ''}`} {...props}>
      {children}
    </form>
  )
}

export const FormGroup = ({children, className, ...props}) => {
  return (
    <div className={`form__group ${className ? ' '+className : ''}`} {...props}>
      {children}
    </div>
  )
}

export const TextField = ({children, className, id, ...props}) => {
  return (
    <>
      <label htmlFor={id} className="form__label">{children}</label>
      <input type="text" id={id} className={`form__textfield ${className ? ' '+className : ''}`} {...props}/>
    </>
  )
}

export const TextToCopy = ({children, className, placeholder, ...props}) => {
  return (
    <div
      className={`form__text-to-copy 
        ${!children ? 'form__text-to-copy--empty' : ''} 
        ${className ? ' '+className : ''}`}
      dangerouslySetInnerHTML={{__html: children || placeholder}}
      tabIndex="0"
      onFocus={(e) => {
        if(children) {
          selectText(e.target)
        }
      }}
      {...props}
    ></div>
  )
}

export const NumberField = ({children, className, id, ...props}) => {
  return (
    <>
      <label htmlFor={id} className="form__label">{children}</label>
      <input type="number" id={id} className={`form__textfield ${className ? ' '+className : ''}`} {...props}/>
    </>
  )
}
export const DateField = ({children, className, id, ...props}) => {
  return (
    <>
      <label htmlFor={id} className="form__label">{children}</label>
      <input
        type="datetime-local"
        id={id}
        className={`form__textfield ${className ? ' '+className : ''}`}
        min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
        {...props}
      />
    </>
  )
}

export const FormTitle = ({children, className, small, ...props}) => {
  return (
    <h2 className={`form__title${small ? '--small' : ''} ${className ? ' '+className : ''}`} {...props}>
      {children}
    </h2>
  )
}

export const FormSeparator = ({children, className, ...props}) => {
  return (
    <div className={`form__separator ${className ? ' '+className : ''}`} {...props}>
      <div className="form__separator-line"></div>
      <div className="form__separator-text">{children}</div>
      <div className="form__separator-line"></div>
    </div>
  )
}