import React from 'react'
import './Button.scss';

export const Button = ({children, className, preventDefault, onClick, ...props}) => {
  const handleOnClick = e => {
    if(preventDefault) {
      e.preventDefault();
    }
    onClick();
  }

  return (
    <button
      className={`button${className ? ' '+className : ''}`}
      {...props}
      onClick={onClick ? handleOnClick : null}
    >
      {children}
    </button>
  )
}