import React, { useContext } from 'react'
import { Link, useRouteMatch } from 'react-router-dom';
import { HiUsers } from "react-icons/hi";
import { MdFormatListBulleted } from "react-icons/md";
import { MdSpaceDashboard } from "react-icons/md";
import { MdDirectionsCar } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";
import { HiOutlineIdentification } from "react-icons/hi";
import './Navigation.scss';
import { AuthContext } from '../../contexts/AuthContext';

export const Navigation = ({className, ...props}) => {
  const {url} = useRouteMatch();
  const {user} = useContext(AuthContext);

  return (
    <nav className={`navigation${className ? ' '+className : ''}`} {...props}>
      <ul className="navigation__list">
        {user?.role === 'admin' && <><li className="navigation__list-item">
          <Link className="navigation__link" to={url}>
            <MdSpaceDashboard className="navigation__icon"/>
            <span>Dashboard</span>
            <MdChevronRight className="navigation__icon--end"/>
          </Link>
        </li>
        <li className="navigation__list-item">
          <Link className="navigation__link" to={`${url}/users`}>
            <HiUsers className="navigation__icon"/>
            <span>Użytkownicy</span>
            <MdChevronRight className="navigation__icon--end"/>
          </Link>
        </li>
        <li className="navigation__list-item">
          <Link className="navigation__link" to={`${url}/logs`}>
            <MdFormatListBulleted className="navigation__icon"/>
            <span>Logi</span>
            <MdChevronRight className="navigation__icon--end"/>
          </Link>
        </li></>}
        <li className="navigation__list-item">
          <Link className="navigation__link" to={`${url}/open-gate`}>
            <MdDirectionsCar className="navigation__icon"/>
            <span>Otwórz bramę</span>
            <MdChevronRight className="navigation__icon--end"/>
          </Link>
        </li>
        {user?.role === 'admin' && <li className="navigation__list-item">
          <Link className="navigation__link" to={`${url}/codes`}>
            <HiOutlineIdentification className="navigation__icon"/>
            <span>Kody dostępu</span>
            <MdChevronRight className="navigation__icon--end"/>
          </Link>
        </li>}
      </ul>
    </nav>
  )
}
