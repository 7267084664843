import React from "react";
import "./Users.scss";
import { Widget } from "../../components/Widget/Widget";
import { UserList } from "../../components/UserList/UserList";

export default function Users() {
  return (
    <Widget className="users__container">
      <UserList className="users__list"/>
    </Widget>
  );
}
