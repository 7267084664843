import axios from "axios";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

export default function useAuth() {
  const [error, setError] = useState(null)
  const { setUser } = useContext(AuthContext);
  const history = useHistory();

  const loginUser = async ({login, password, code}) => {
    return axios.post(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/auth/login`,
      ((!login || !password) && !!code) ? {code} : {login, password})
    .then(async res => {
      if(!res?.data?.accessToken) throw new Error('Wystąpił błąd, nie można zalogować użytkownika');

      localStorage.setItem('LMI_JWT', res?.data?.accessToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${res?.data?.accessToken}`;
      
      return axios.get(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/auth/me`);
    })
    .then(res => {
      setUser(res.data);
      history.push(res.data.role === 'admin' ? '/app' : '/app/open-gate');
    })
    .catch(err => {
      setError(err?.response?.data?.message || err?.message)
    });
  }

  const logout = async () => {
    localStorage.removeItem('LMI_JWT');
    setUser(null);
    history.push('/');
  };

  return {loginUser, logout, error};
}