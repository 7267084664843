import React from 'react'
import './UserBadge.scss';
import avatar from '../../assets/avatar.png';
import { HiOutlineBan } from 'react-icons/hi';

export const UserBadge = ({user, actions, className, round, ...props}) => {
  return (
    <div className={`badge${className ? ' '+className : ''}${round ? ' badge--round-avatar' : ''}`} {...props}>
      <div className="badge__avatar">
        <img src={avatar} alt="" />
      </div>
      <div className="badge__info">
        <div className="badge__title">{user?.name}{!!user.isBanned && <HiOutlineBan className="user-banned-icon"/>}</div>
        <div className="badge__subtitle">{user?.login || user?.code}</div>
      </div>
      <div className="badge__actions">
        {actions}
      </div>
    </div>
  )
}
