import axios from "axios";
import React, { useState } from "react";
import { BiLockOpenAlt } from "react-icons/bi";
import { Button } from "../Button/Button";
import "./OpenGateBtn.scss";

export default function OpenGateBtn({refreshLogs}) {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const openGate = () => {
    axios.post(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/open`).then(() => {
      if(refreshLogs) {
        refreshLogs();
      }
    }).catch(err => {
      alert(err?.response?.data?.message || err?.message);
    });
    setBtnDisabled(true);
    setTimeout(() => {
      setBtnDisabled(false);
    }, 5000);
    
  }

  return (
    <>
      <span className="open-gate-widget__title">Otwórz bramę</span>
      <Button className={`open-gate-widget__button${btnDisabled ? '--disabled' : ''}`} disabled={btnDisabled ? 'disabled' : ''} onClick={openGate}>
        <BiLockOpenAlt />
      </Button>
    </>
  );
}
