import axios from "axios";
import { useEffect, useState } from "react";

export default function useGetUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('LMI_JWT');
    
    const getUser = async () => {
      await axios.get(`${window?.config?.API_URL || process.env.REACT_APP_API_URL}/auth/me`)
        .then(res => {
          setUser(res.data);
        }).catch(err => {
          console.log(err);
        }).finally(() => {
          setIsLoading(false);
        });
    }
    
    if(token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      getUser();
    } else {
      setIsLoading(false);
    }
  }, []);

  return {user, setUser, isLoading};
}