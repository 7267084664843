import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Error } from '../../components/Error/Error';
import { Form, FormGroup, FormSeparator, FormTitle, TextField } from '../../components/Form/Form';
import { AuthContext } from '../../contexts/AuthContext';
import useAuth from '../../hooks/useAuth';
import './Login.scss';

export default function Login() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const {loginUser, error} = useAuth();
  const {user} = useContext(AuthContext);
  const {codeParam} = useParams();
  
  const logIn = () => {
    loginUser({login, password, code});
  }

  useEffect(() => {
    if(codeParam) {
      loginUser({code: codeParam});
    }
  }, [codeParam])

  if(user) return <Redirect to='/app'/>;
  return (
    <div className="container--center-content">
      <Form className="login-form">
        <FormTitle>Let me in</FormTitle>
        <FormGroup>
          <TextField value={login} onChange={e => {setLogin(e.target.value)}}>Login:</TextField>
        </FormGroup>
        <FormGroup>
          <TextField value={password} type="password" onChange={e => {setPassword(e.target.value)}}>Hasło:</TextField>
        </FormGroup>
        <FormSeparator>lub</FormSeparator>
        <FormGroup>
          <TextField value={code} onChange={e => {setCode(e.target.value)}}>Kod dostępu:</TextField>
        </FormGroup>
        {!!error && <FormGroup>
          <Error>{error}</Error>
        </FormGroup>}
        <FormGroup>
          <Button onClick={logIn} preventDefault>Zaloguj</Button>
        </FormGroup>
      </Form>
    </div>
  )
}
