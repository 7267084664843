export const selectText = elem => {
  let range;
  if (document.selection) { // IE
      range = document.body.createTextRange();
      range.moveToElementText(elem);
      range.select();
  } else if (window.getSelection) {
      range = document.createRange();
      range.selectNode(elem);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
  }
}