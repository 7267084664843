import React, { useContext } from 'react'
import { AiOutlineLogout } from 'react-icons/ai'
import { AuthContext } from '../../contexts/AuthContext'
import useAuth from '../../hooks/useAuth'
import { Button } from '../Button/Button'
import { Navigation } from '../Navigation/Navigation'
import { UserBadge } from '../UserBadge/UserBadge'
import './Sidebar.scss'

export const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const {logout} = useAuth();

  return (
    <div className="sidebar">
      <h1 className={`sidebar__title ${user.role !== 'admin' ? 'hide' : ''}`}>Let me in</h1>
      <UserBadge user={user} actions={<Button className="button--icon" onClick={logout} title="Wyloguj się"><AiOutlineLogout/></Button>} round/>
      <Navigation className={`${user.role !== 'admin' ? 'hide' : ''}`}/>
    </div>
  )
}
